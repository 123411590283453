import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../../../axiosSetup";
import axios, { AxiosError } from "axios";
import queryString from "query-string";

export const getContainerListValues = createAsyncThunk(
  "/getContainerListValues",
  async (payload: any) => {
    try {
      return payload.map((data: string) => ({
        blNumber: crypto.randomUUID(),
        containerNo: data,
        groupId: crypto.randomUUID(),
        groupDesc: crypto.randomUUID(),
        remove: crypto.randomUUID(),
        rowNumber: crypto.randomUUID(),
      }));
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getRefNo = createAsyncThunk(
  "bestChoiceDashboard/getRefNo",
  async (country: any) => {
    try {
      const response = await Axios.post(
        `/api/opc-rest-v1/v1/bestPick/generateBestPickReferenceNoByCountry?countryCode=${country}`
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
    }
  }
);

export const bestPickView = createAsyncThunk(
  "bestChoiceDashboard/bestPickView",
  async (id: any) => {
    try {
      const response = await Axios.get(
        `/api/opc-rest-v1/v1/bestPick/getBestPickRequest?id=${id}`
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
    }
  }
);

export const bestPickTotalContainers = createAsyncThunk(
  "bestChoiceDashboard/bestPickTotalContainers",
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `/api/opc-rest-v1/v1/bestPick/getBestPickContainers?groupId=${payload.selectedBestPickGrpId}&vessel=${payload.vessel}&placeOfDelivery=${payload.placeOfDelivery}`,
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
    }
  }
);

export const ICDCode = createAsyncThunk(
  "bestChoiceDashboard/ICDCode",
  async (tpoID: any) => {
    try {
      const response = await Axios.post(
        `/api/opc-rest-v1/v1/bestPick/getIcdCodes?tpoId=${tpoID}`
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
    }
  }
);

export const bestPickGroupID = createAsyncThunk(
  "bestChoiceDashboard/bestPickGroupID",
  async (payload: any) => {
    //debugger;
    try {
      const response = await Axios.post(
        `/api/opc-management/v1/bestPick/getBestPickGroups?visitKey=${payload.vessel}&shortCode=${payload.shortCodeVal}`
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
    }
  }
);

export const bestPickCodes = createAsyncThunk(
  "bestChoiceDashboard/bestPickCodes",
  async (payload: any) => {
    try {
      const response = await Axios.post(
        `/api/opc-rest-v1/v1/bestPick/getIcdBestPickCodes`,
        payload
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;
      return error;
      // console.error(error);
    }
  }
);

export const submitBestPickCodes = createAsyncThunk(
  "bestChoiceDashboard/submitBestPickCodes",
  async (payload: any) => {
    try {
      const response = await Axios.post(
        `/api/opc-management/v1/bestPick/submitBestPickCodeRequest`,
        payload
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
    }
  }
);
