import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  addRegistrationDetails,
  fetchAddressSuggestions,
  getCompanyTypes,
  getPortOperatorTypes,
  getRegistrationDetails,
  getRegistrationDocuments,
  getRegistrationIndemnityDocuments,
  getRegistrationPartnerDocConfig,
  getRegistrationTaxDocConfig,
  getRegistrationTaxDocVerify,
  getSeaPortTypes,
  getShippingLineTypes,
} from "./actions/Registration";

export interface RegistrationDetails {
  filterCountries: string;
  text: string;
}
export interface IRegistrationList {
  list: RegistrationDetails[];
}

export interface IRegistrationAddressSuggesstion {
  text: string;
  placeId: string;
}

export interface RegistrationSlice {
  data: IRegistrationList;
  // showColumnSettings: boolean;
  registrationAddressSuggesstion: IRegistrationAddressSuggesstion[];
  selectedAddressResult: string;
  selectedPlaceId: string;
  isAddressSuggesstionsLoading: boolean;
  taxId: any;
  IndemnityLetterManditoryCheck: any;
  indemnityLetter: any;
  isSubmitted: boolean;
  companyName: any;
  address: any;
  countryOptions: any;
  selectedCountry: string;
  linerOptions: any;
  documentOptions: Array<{
    text: string;
    value: number;
    isVerificationReq: boolean;
    m: {
      number: boolean;
      issueDate: boolean;
      validityDate: boolean;
      attachment: boolean;
    };
  }>;
  companyTypeOptions: Array<any>;
  shippinglineOptions: any;
  seaPortOptions: any;
  portOperatorOptions: any /* Array<any> */;
  partnerTable: Array<{
    rowNumber: any;
    customerCode: String;
    liner: String;
    attachmentName: String;
    serverPath: String | null;
    serverName: String | null;
    loading: number;
    remove: true;
    m: boolean;
    error: {
      attachment: boolean;
      customerCode: boolean;
    };
  }>;
  documentTable: Array<{
    regPartnerDocumentId?: any;
    verified: "N" | "Y";
    rowNumber: any;
    docId: Number;
    number: String;
    isApproved: string;
    issueDate: Date | null;
    validityDate: Date | null;
    attachmentName: String;
    serverPath: String | null;
    serverName: String | null;
    loading: number;
    docName:string;
    m: {
      number: boolean;
      issueDate: boolean;
      validityDate: boolean;
      attachment: boolean;
      isRequired?:boolean;
    };
    error: {
      number: boolean;
      issueDate: boolean;
      validityDate: boolean;
      attachment: boolean;
    };
  }>;
  taxDocument: {
    name: string;
    serverPath: string | null;
    serverName: string | null;
    loading: number;
    m: boolean;
    error: boolean;
    isVerificationReq: boolean;
  };
  indemnityDocument: {
    name: string;
    serverPath: string | null;
    serverName: string | null;
    loading: number;
    m: boolean;
    error: boolean;
    isVerificationReq: boolean;
  };
}

const initialState: RegistrationSlice = {
  data: {
    list: [],
  },

  selectedAddressResult: "",
  selectedPlaceId: "",
  isAddressSuggesstionsLoading: false,
  IndemnityLetterManditoryCheck: [],
  registrationAddressSuggesstion: [],
  taxId: {},
  indemnityLetter: {},
  companyName: {},
  address: {},
  countryOptions: [],
  seaPortOptions: [],
  shippinglineOptions: [],
  portOperatorOptions: [],
  isSubmitted: false,
  linerOptions: [],
  companyTypeOptions: [],

  taxDocument: {
    name: "",
    serverPath: null,
    serverName: null,
    loading: 0,
    m: true,
    error: false,
    isVerificationReq: false,
  },
  selectedCountry: "",
  indemnityDocument: {
    name: "",
    serverPath: null,
    serverName: null,
    loading: 0,
    m: true,
    error: false,
    isVerificationReq: false,
  },
  partnerTable: [
    {
      rowNumber: crypto.randomUUID(),
      customerCode: "",
      liner: "",
      attachmentName: "",
      serverName: null,
      serverPath: null,
      loading: 0,
      remove: true,
      m: true,
      error: {
        attachment: false,
        customerCode: false,
      },
    },
  ],
  documentTable: [],
  documentOptions: [],
};

export const RegistrationReducer = createSlice({
  name: "registration",
  initialState,
  reducers: {
    updateSelectedAddressResult: (state, action: PayloadAction<any>) => {
      state.selectedAddressResult = action.payload.text;
      state.selectedPlaceId = action.payload.placeId;
    },
    updateTax: (state, action: PayloadAction<any>) => {
      state.taxId = action.payload;
    },
    updateCompanyName: (state, action: PayloadAction<any>) => {
      state.companyName = action.payload;
    },
    updateAddress: (state, action: PayloadAction<any>) => {
      state.address = action.payload;
    },
    updateCountryOptions: (state, action: PayloadAction<any>) => {
      state.countryOptions = action.payload;
    },
    updateSeaPortOptions: (state, action: PayloadAction<any>) => {
      state.seaPortOptions = action.payload;
    },
    updatePortOperatorOptions: (state, action: PayloadAction<any>) => {
      state.portOperatorOptions = action.payload;
    },
    updateShippingLineOptions: (state, action: PayloadAction<any>) => {
      state.shippinglineOptions = action.payload;
    },
    updateLinerOptions: (state, action: PayloadAction<any>) => {
      state.linerOptions = action.payload;
    },

    addPartnerTableRow: (state, action) => {
      state.partnerTable.push({
        rowNumber: action.payload.key,
        customerCode: "",
        liner: state.linerOptions[0]?.value ?? "",
        attachmentName: "",
        serverName: null,
        loading: 0,
        serverPath: null,
        remove: true,
        m: action.payload.m,
        error: {
          attachment: false,
          customerCode: false,
        },
      });
    },
    addDocumentTableRow: (state) => {
      state.documentTable.push({
        rowNumber: crypto.randomUUID(),
        docId: 12,
        docName:'Others',
        number: "",
        issueDate: null,
        isApproved: "N",
        validityDate: null,
        attachmentName: "",
        serverName: null,
        loading: 0,
        serverPath: null,
        verified: "N",
        m: state?.documentOptions?.[0]?.m ?? {
          number: true,
          issueDate: true,
          validityDate: true,
          attachment: true,
          isRequired:true
        },
        error: {
          number: false,
          issueDate: false,
          validityDate: false,
          attachment: false,
        },
      });
    },
    
    addDocumentTableRowTz: (state) => {
      state.documentTable.push({
        rowNumber: crypto.randomUUID(),
        docId:0,
        number: "",
        issueDate: null,
        isApproved: "N",
        validityDate: null,
        attachmentName: "",
        serverName: null,
        loading: 0,
        docName:'Others',
        serverPath: null,
        verified: "N",
        m:  {
          number: true,
          issueDate: true,
          validityDate: true,
          attachment: true,
          isRequired:true
        },
        error: {
          number: false,
          issueDate: false,
          validityDate: false,
          attachment: false,
        },
      });
    },
    removePartnerTableRow: (state, action) => {
      if (state.partnerTable.length <= 1) {
        state.partnerTable = [
          {
            rowNumber: action.payload.key,
            customerCode: "",
            liner: state.linerOptions[0]?.value ?? "",
            attachmentName: "",
            serverName: null,
            loading: 0,
            serverPath: null,
            remove: true,
            m: action.payload.m,
            error: {
              attachment: false,
              customerCode: false,
            },
          },
        ];
        return;
      }
      state.partnerTable = state.partnerTable.filter(
        (i) => i.rowNumber !== action.payload.rowNumber
      );
    },
    removeDocumentTableRow: (state, action) => {
      state.documentTable = state.documentTable.filter(
        (i) => i.rowNumber !== action.payload.rowNumber
      );
    },
    setPartnerTableRow: (state, action) => {
      const row: any = state.partnerTable.find(
        (x) => x.rowNumber == action.payload.rowNumber
      );
      if (!row) return;
      Object.entries(action.payload).forEach(([key, val]) => {
        if (Object.hasOwn(row, key)) row[key] = val;
      });
    },
    setPartnerTableCols: (state, action) => {
      // const row: any = state.partnerTable.find(
      //   (x) => x.rowNumber == action.payload.rowNumber
      // );
      // if (!row) return;
      state.partnerTable.forEach((item: any) => {
        Object.entries(action.payload).forEach(([key, val]) => {
          if (Object.hasOwn(item, key)) item[key] = val;
        });
      });
    },
    setDocumentTableRow: (state, action) => {
      const row: any = state.documentTable.find(
        (x) => x.rowNumber == action.payload.rowNumber
      );
      if (!row) return;

      Object.entries(action.payload).forEach(([key, val]) => {
        if (Object.hasOwn(row, key)) {
          if (key === "verified") {
            // Toggle the verified state
            row[key] = val === "Y" ? "N" : "Y";
          } else {
            row[key] = val;
          }
        }

        if (key === "docId") {
          row.m = state.documentOptions.find((item) => item.value == val)
            ?.m ?? {
            number: true,
            issueDate: true,
            validityDate: true,
            attachment: false,
          };
        }

        // Handle the verified field separately if needed
        if (key === "verified") {
          row.verified = val; // Update the verified field when checkbox is checked/unchecked
        }
      });
    },
    updateTaxDocument: (state, action) => {
      state.taxDocument = { ...state.taxDocument, ...action.payload };
    },
    updateIndemnityDocument: (state, action) => {
      state.indemnityDocument = {
        ...state.indemnityDocument,
        ...action.payload,
      };
    },
    updateSelectedCountry: (state, action: PayloadAction<any>) => {
      state.selectedCountry = action.payload;
    },

    setIsSubmitted: (state, action: PayloadAction<boolean>) => {
      state.isSubmitted = action.payload;
    },

    validationCheckRegistration: (state) => {
      state.taxDocument.error = !!(
        state.taxDocument.m &&
        (state.taxDocument.serverPath == null ||
          state.taxDocument.loading != 100)
      );
      state.indemnityDocument.error = !!(
        state.indemnityDocument.m &&
        (state.indemnityDocument.serverPath == null ||
          state.indemnityDocument.loading != 100)
      );
      state.documentTable.forEach((item) => {
        item.error.attachment = !!((item.serverPath == null || item.loading !== 100) && item?.m?.attachment );
        item.error.issueDate = !!(item.m.issueDate && item.issueDate == null);
        item.error.validityDate = !!(item.m.validityDate && item.validityDate == null);
        item.error.number = !!(item.m.number && item.number=="");
      });
      state.partnerTable.forEach((item) => {
        item.error.attachment = !!(
          item.m &&
          item.customerCode &&
          (item.serverPath == null || item.loading != 100)
        );
        item.error.customerCode = !!(
          item.serverPath && item.customerCode.trim() == ""
        );
      });
    },
    clearRegistrationFiles: (state) => {
      state.taxDocument.name = "";
      state.taxDocument.loading = 0;
      state.taxDocument.serverPath = null;
      state.taxDocument.serverName = null;
      state.taxDocument.error = false;
      state.indemnityDocument.name = "";
      state.indemnityDocument.loading = 0;
      state.indemnityDocument.serverPath = null;
      state.indemnityDocument.serverName = null;
      state.indemnityDocument.error = false;
      state.partnerTable.forEach((row) => {
        row.attachmentName = "";
        row.serverName = null;
        row.serverPath = null;
        row.loading = 0;
      });

      state.documentTable.forEach((row) => {
        row.attachmentName = "";
        row.serverPath = null;
        row.serverName = null;
        row.loading = 0;
        row.verified = "N";
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addRegistrationDetails.pending, (state, action) => {});
    builder.addCase(addRegistrationDetails.fulfilled, (state: any, action) => {
      state.dataLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAddressSuggestions.pending, (state, action) => {
      state.isAddressSuggesstionsLoading = true;
    });

    builder.addCase(fetchAddressSuggestions.fulfilled, (state, action) => {
      state.isAddressSuggesstionsLoading = false;
      state.registrationAddressSuggesstion = action.payload;
    });

    builder.addCase(getCompanyTypes.fulfilled, (state, action) => {
      state.companyTypeOptions = action.payload;
    });
    builder.addCase(getPortOperatorTypes.fulfilled, (state, action) => {
      state.portOperatorOptions = action.payload;
    });

    builder.addCase(getShippingLineTypes.fulfilled, (state, action) => {
      state.shippinglineOptions = action.payload;
    });

    builder.addCase(getSeaPortTypes.fulfilled, (state, action) => {
      state.seaPortOptions = action.payload;
    });
    builder.addCase(getRegistrationDetails.pending, (state) => {
      state.documentTable = [];
      state.taxDocument = {
        name: "",
        serverPath: null,
        serverName: null,
        loading: 0,
        m: false,
        error: false,
        isVerificationReq: false,
      };
      state.indemnityDocument = {
        name: "",
        serverPath: null,
        serverName: null,
        loading: 0,
        m: false,
        error: false,
        isVerificationReq: false,
      };
      state.partnerTable = [
        {
          rowNumber: crypto.randomUUID(),
          customerCode: "",
          liner: state.linerOptions[0]?.value ?? "",
          attachmentName: "",
          serverName: null,
          serverPath: null,
          loading: 0,
          remove: true,
          m: true,
          error: {
            attachment: false,
            customerCode: false,
          },
        },
      ];
    });

    builder.addCase(getRegistrationDetails.fulfilled, (state, action: any) => {
      state.documentTable =
        action.payload?.data?.customerDocumentInputs?.map((item: any) => ({
          regPartnerDocumentId: item.partnerDocumentId,
          rowNumber: crypto.randomUUID(),
          docId: item.docId,
          number: item.docNo,
          isApproved: item.isApproved,
          issueDate: item?.issueDate ? new Date(item.issueDate) : null,
          validityDate: item?.validTillDate
            ? new Date(item.validTillDate)
            : null,
          attachmentName: item.fileName,
          serverName: null,
          serverPath: null,
          loading: 0,
          m: {
            number: true,
            issueDate: true,
            validityDate: true,
            attachment: false,
            isRequired: [400]?.includes(item?.docId)?false:true,
          },
          verified: item.isVerificationReq,
          error: {
            number: false,
            issueDate: false,
            validityDate: false,
            attachment: false,
          },
        })) ?? [];
      state.taxDocument = {
        name: action.payload.data?.taxFileName ?? "",
        serverPath: null,
        serverName: null,
        loading: 1,
        m: false,
        error: false,
        isVerificationReq: false,
      };
      state.indemnityDocument = {
        name: action.payload.data?.loiFileName ?? "",
        serverPath: null,
        serverName: null,
        loading: 1,
        m: false,
        error: false,
        isVerificationReq: false,
      };
      state.partnerTable = action.payload?.data.customerMapping?.map(
        (item: any) => ({
          rowNumber: crypto.randomUUID(),
          customerCode: item.mapCode,
          liner: item.linerId,
          attachmentName: item.fileName,
          serverPath: null,
          serverName: null,
          loading: 0,
          remove: false,
          m: false,
          error: {
            attachment: false,
            customerCode: false,
          },
        })
      ) ?? [
        {
          rowNumber: crypto.randomUUID(),
          customerCode: "",
          liner: state.linerOptions[0]?.value ?? "",
          attachmentName: "",
          serverName: null,
          serverPath: null,
          loading: 0,
          remove: true,
          m: true,
          error: {
            attachment: false,
            customerCode: false,
          },
        },
      ];
    });

    builder.addCase(
      getRegistrationDocuments.fulfilled,
      (state, action: any) => {
        state.documentOptions = action.payload.map((item: any) => ({
          text: item.docName,
          value: item.docId,
          m: {
            number: item.docNo == "Y",
            issueDate: item.issueDate == "Y",
            validityDate: item.validTillDate == "Y",
            isVerificationReq: item.isVerificationReq === "Y",
            attachment: false,
          },
        }));
        if (state.documentTable?.[0]?.regPartnerDocumentId != undefined) {
          return;
        }
        state.documentTable = action.payload
          // .filter((i: any) => i.isMandatory == "Y")
          .map((item: any) => {
            return (
              {
                rowNumber: crypto.randomUUID(),
                docId: item.docId,
                number: "",
                issueDate: null,
                validityDate: null,
                attachmentName: "",
                serverName: null,
                docName: item.docName,
                serverPath: null,
                loading: 0,

                m: item?.isMandatory == 'Y' ? {
                  isRequired: true,
                  hideTrashIcon:true,
                  number: item.docNo == "Y",
                  issueDate: item.issueDate == "Y",
                  validityDate: item.validTillDate == "Y",
                  isVerificationReq: item.isVerificationReq === "Y",
                  attachment: item.docId == 400 ? false : true,
                } :
                  {
                    isRequired: false,
                  hideTrashIcon:true,
                    number: false,
                    issueDate: false,
                    validityDate: false,
                    isVerificationReq: false,
                    attachment: item.docId == 400 ? false : true,
                  },
                error: {
                  number: false,
                  issueDate: false,
                  validityDate: false,
                  attachment: false,
                },
              });
          });
      }
    );
    builder.addCase(
      getRegistrationIndemnityDocuments.fulfilled,
      (state, action: any) => {
        state.IndemnityLetterManditoryCheck = action.payload.filter((i: any) =>
          i.isMandatory == "Y" ? "Y" : "N"
        );

        state.indemnityDocument.isVerificationReq =
          action?.payload[0]?.isVerificationReq === "Y";
      }
    );
    builder.addCase(getRegistrationTaxDocConfig.fulfilled, (state, action) => {
      state.taxDocument.m = action.payload == "Y";
    });
    builder.addCase(getRegistrationTaxDocVerify.fulfilled, (state, action) => {
      //  action.payload = "Y";
      // state.taxDocument.isVerificationReq = action.payload == "Y";
      state.taxDocument.isVerificationReq = action.payload === "Y";

      /*   state.taxDocument = {
        ...state.taxDocument,
        isVerificationReq: action.payload === "Y",
      }; */
    });
    builder.addCase(
      getRegistrationPartnerDocConfig.fulfilled,
      (state, action) => {}
    );
  },
});

export const {
  updateSelectedAddressResult,
  updateTax,
  updateCompanyName,
  updateAddress,
  updateCountryOptions,
  updatePortOperatorOptions,
  updateLinerOptions,
  addPartnerTableRow,
  removePartnerTableRow,
  setPartnerTableRow,
  setDocumentTableRow,
  updateTaxDocument,
  updateIndemnityDocument,
  addDocumentTableRow,
  updateSeaPortOptions,
  removeDocumentTableRow,
  updateShippingLineOptions,
  setIsSubmitted,
  validationCheckRegistration,
  updateSelectedCountry,
  addDocumentTableRowTz,
  setPartnerTableCols,
  clearRegistrationFiles,
} = RegistrationReducer.actions;

export default RegistrationReducer.reducer;
