import moment from "moment"
import { AxiosError } from "axios"
import { toast } from "react-toastify"

import { createAsyncThunk } from "@reduxjs/toolkit"

import { RootState } from "../../store"
import retry from "../../../helpers/axiosRetry"
import axiosInstance, { axiosCacheInstance } from "../../../axiosSetup"

export const getPortIdDocList = createAsyncThunk(
    'portId/getPortIdDocList',
    async ({ dataCountry, partner, tpoId, type }: any) => {
        try {
            const data = (await axiosCacheInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/common/getAttachmentConfig?countryCode=${dataCountry
                }&instructionTy=905&tpoId=${tpoId}&partnerType=${partner}&category=${type}`,
                undefined,
                {
                    cache: {
                        ttl: 10 * 60 * 1000
                    }
                }
            )).data

            return data.map((d: any) => ({
                rowNumber: crypto.randomUUID(),
                attId: null,
                docId: d.docId,
                docName: d.docName,
                fileName: '',
                filePath: '',
                tempName: '',
                serverFilePath: '',
                m: d.isMandatory == 'Y',
                loading: 0
            }))
        } catch {
            return []
        }

    })

export const getPortIdDocumentNames = createAsyncThunk(
    'portId/getPortIdDocumentNames',
    async ({ dataCountry, partner, tpoId, type }: any) => {
        try {
            const data = (await axiosCacheInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/common/getAttachmentConfig?countryCode=${dataCountry
                }&instructionTy=905&tpoId=${tpoId}&partnerType=${partner}&category=${type}`,
                undefined,
                {
                    cache: {
                        ttl: 10 * 60 * 1000
                    }
                }
            )).data
           
            return [...data.map((d: any) => ({
                docId: d?.docId,
                docName: d?.docName,
                m: d?.isMandatory == 'Y',
            })), { docId: 12, docName: 'Others' }]
        } catch (e) {
            console.error('portIdDocConfigError',e)
            return []
        }

    })

export const getStatusOptions = createAsyncThunk(
    'opc/getStatusOptions',
    async (payload: { tpoId: any, country: any }) => {
        try {
            const data = (await retry(async () => await axiosCacheInstance.get(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=EVENT_TYPE&linerId=${payload.tpoId}&countryCode=${payload.country}`,
                {
                    cache: {
                        ttl: 10 * 60 * 1000
                    }
                }
            ), 2))?.data;
           
            return data.map(
                (d: any) => ({
                    text: d.codeDesc,
                    value: d.codeId,
                })
            )
        } catch {
            return []
        }

    })

export const getportIdCardStatusForUser = createAsyncThunk(
    'portId/getPortIdrequest',
    async (mailId: any) => {
        try {
            const response = await axiosInstance.get(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/portIdCard/getportIdCardStatus?mailId=${mailId}`
            )
          
            return response.data;
        } catch {
            toast.error('Error while getting port id status', {
                toastId: 'Bn213vS'
            });
        }
    })

export const PortIdApproveReject = createAsyncThunk(
    'mps/PortIdApproveReject',
    async ({ requestedOn, ptoRemarks, tpoId, requestId, status, validTo, validFrom }: any, { getState }: any) => {
        const state: RootState = getState();
        try {
            const payload = {
                "portIdReqId": requestId,
                "status": status, //311,
                "approvalRemarks": ptoRemarks,
                "requestedOn": requestedOn,// from get api
                "tpoId": tpoId,
                "instType": 905,
                "validTo": validTo ? moment(validTo).format("YYYY-MM-DDTHH:mm:ssZ") : '',
                "validFrom": validTo ? moment(validFrom).format("YYYY-MM-DDTHH:mm:ssZ") : ''
                // "navisDraftId": navisDraftId, // null for all approval level, only on final 311 set this field
                // "approvalLevel":level //approval level from get api
            }
            const response = await axiosInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/portIdCard/approveRejectIdCardRequest`,
                payload
            )
            if (response?.data?.responseCode == 'APPROVED' ||
                response?.data?.responseCode == 'REJECTED' ||
                response?.data?.responseCode == 'SUCCESS'
            ) {
                toast.success(response?.data?.responseMsg, {
                    toastId: '4b4de93'
                })
            } else {
                toast.error(response?.data?.responseMsg ?? `Error while ${status == 304 ? 'rejecting' : 'approving'} Port Id request`, {
                    toastId: '51db340'
                });
            }
        } catch {
            toast.error(`Error while ${status == 304 ? 'rejecting' : 'approving'} Port Id request`, {
                toastId: 'dd7bb3d'
            });
        }
    })

export const saveOrDraftPortId = createAsyncThunk(
    'portId/saveOrDraftPortId',
    async (data: any, { getState }: any) => {
        const state: RootState = getState();
        try {
            const payload = {
                idRequestRefNo: data.ref_no,
                tpoId: parseInt(data.tpoId),
                userMail: data.userEmail,
                typeId: parseInt(data.For),
                requestedOn: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                requestedPartnerNm: data.companyName,
                firstName: data.firstName,
                middleName: data.middleName,
                lastName: data.lastName,
                phoneNumber: data.phoneNo,
                validFrom: data.validFrom ? moment(data.validFrom).format("YYYY-MM-DDTHH:mm:ssZ") : '',
                validTo: data.validTo ? moment(data.validTo).format("YYYY-MM-DDTHH:mm:ssZ") : '',
                duration: parseInt(data.requestDuration),
                nationalIdType: parseInt(data.nationalIdType),
                nationalIdNumber: data.nationalIdNumber,
                remarks: data.remarks,
                emergencyContactNumber: data.emergencyContactNumber,
                identityNo: data.companyTin,
                documents: state.portId.documentData?.map(({ docId, filePath, fileName, serverFilePath, tempName, attId }: any) => ({
                    docId: docId,
                    filePath: filePath,
                    fileName: fileName,
                    serverFilePath: serverFilePath,
                    tempFileName: tempName,
                    ...((data.portIdReqId && attId) && { portIdReqAttId: attId })
                })),
                ...((data.portIdReqId) && { portIdReqId: data.portIdReqId }),
                status: data.isDraft ? 314 : 303,
            };

            const response = await axiosInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/portIdCard/submitPortIdCardRequest`,
                payload
            );

            response?.data?.responseCode === 'SUCCESS'
                ? toast.success(response.data.responseMsg)
                : toast.error(response.data.responseMsg || 'Error occurred');

        } catch (e) {
            toast.error(`Error while saving ${data.isDraft ? 'draft' : ''} Port ID request`);
        }
    }
);


export const cancelPortIdRequest = createAsyncThunk(
    'mps/cancelrequest',
    async (payload: any) => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/portIdCard/cancelIdCardRequest?portIdReqId=${payload.id}`)
            if (response?.data?.responseCode == 'CANCELLED') {
                toast.success(response?.data?.responseMsg)
            } else {
                toast.error(response?.data?.responseMsg ?? 'Error while canceling Port Id request');
            }
        } catch (e: any) {
            toast.error(e?.response?.data?.responseMsg ?? 'Error while canceling Port Id request');
        }
    })

export const validateUserEmail = createAsyncThunk(
    'portId/validateUserEmail',
    async (email: any) => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/portIdCard/checkIdCardPresent?mailId=${email}`)
            return response?.data
        } catch (e: any) {
            const error = e as AxiosError;
            return {};
        }
    })

export const getPortIDRequest = createAsyncThunk(
    'mps/getPortIDRequest',
    async ({ tpoId, id, dataCountry, opcPartnerType }: any) => {
        try {
            const requestPromise = axiosInstance.get(
                `/api/opc-rest-v1/v1/portIdCard/getIdCardRequest?id=${id}&instType=904&tpoId=${tpoId}`
            );
            const attConfPromise = axiosInstance.post(
                `${process.env.REACT_APP_API_GATEWAY_HOST}/api/opc-rest-v1/v1/common/getAttachmentConfig?countryCode=${dataCountry
                }&instructionTy=905&tpoId=${tpoId}&partnerType=${opcPartnerType}&category=1`
            )
            const data = (await requestPromise).data
            const conf = (await attConfPromise).data

            const docs = [
                ...conf.map((d: any) => {
                    const f = data.documents.find((x: any) => x.docId == d.docId)
                    return ({
                        rowNumber: crypto.randomUUID(),
                        attId: f?.portIdReqAttId,
                        docId: d?.docId,
                        filePath: f?.filePath,
                        fileName: f?.fileName,
                        serverFilePath: '',
                        tempName: '',
                        loading: 0,
                        docName: d?.docName,
                        m: d?.isMandatory == 'Y',
                    })
                }),
                ...data.documents.filter((x: any) => x?.docId == 12).map((f: any) => ({
                    rowNumber: crypto.randomUUID(),
                    attId: f?.portIdReqAttId,
                    docId: 12,
                    filePath: f?.filePath,
                    fileName: f?.fileName,
                    serverFilePath: '',
                    tempName: '',
                    loading: 0,
                    docName: 'Others',
                    m: true,
                }))
            ]

            return {
                firstName: data?.firstName,
                ref_no: data?.idRequestRefNo,
                companyTin: data?.identityNo,
                companyName: data?.requestedPartnerNm,
                middleName: data?.middleName,
                lastName: data?.lastName,
                nationalIdNumber: data?.nationalIdNumber,
                remarks: data?.remarks,
                validFrom: data?.validFrom,
                validTo: data?.validTo,
                For: data?.typeId,
                terminalOperator: data?.tpoId,
                userEmail: data?.userMail,
                phoneNo: data?.phoneNumber,
                requestDuration: data?.duration,
                nationalIdType: data?.nationalIdType,
                emergencyContactNumber: data?.emergencyContactNumber,
                status: data?.status,
                portIdReqId: data?.portIdReqId,
                documents: docs,
                requestedOn: data?.requestedOn,
                requestedPartnerType: data?.requestedPartnerType,
                approvalRemarks: data?.approvalRemarks,
                navisDraftId: data?.draftId
            };
        } catch (error) {
            toast.error('Error fetching port ID request:',{toastId:'cQxtt'});
        }
    }
);

export const getNationalIdOptions = createAsyncThunk(
    'portId/getNarionalIdOptions',
    async ({ dataCountry, tpoId }: any) => {
        try {
            const response = await retry(async () => await axiosCacheInstance.get(
                `/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=NATIONAL_ID_TYPE&linerId=${tpoId}&countryCode=${dataCountry}`,
                {
                    cache: {
                        ttl: 10 * 60 * 1000
                    }
                }
            ), 2);

            const nationalIdOptions = (response?.data ?? []).map((item: any) => ({
                value: item.codeId,
                text: item.codeDesc,
            }));
            return nationalIdOptions
        } catch (e) {
            console.error('getNationalIdOptionsError:', e)
        }
    }
)

export const getDurationOptions = createAsyncThunk(
    'portId/getDurationOptions',
    async ({ dataCountry, tpoId }: any) => {
        try {
            const response = await retry(async () => await axiosCacheInstance.get(
                `/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=DURATION_TYPE&linerId=${tpoId}&countryCode=${dataCountry}`,
                {
                    cache: {
                        ttl: 10 * 60 * 1000
                    }
                }
            ), 2);

            const nationalIdOptions = response?.data && response?.data?.length > 0 ?
                response?.data?.map((item: any) => ({
                    value: item?.codeId,
                    text: item?.codeDesc,
                })) : [];
            return nationalIdOptions
        } catch (e) {
            console.error('getDurationOptionsError:', e)
        }
    }
)


export const getPortIdTypeOptions = createAsyncThunk(
    'portId/getPortIdTypeOptions',
    async ({ dataCountry, tpoId }: any) => {
        try {
            const response = await retry(async () => await axiosCacheInstance.get(
                `/api/opc-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=PORT_ID_TYPE&linerId=${tpoId}&countryCode=${dataCountry}`,
                {
                    cache: {
                        ttl: 10 * 60 * 1000
                    }
                }
            ), 2);

            const portIdOptions = response?.data && response?.data?.length > 0 ?
                response?.data?.map((item: any) => ({
                    value: item?.codeId,
                    text: item?.codeDesc,
                })) : [];
            return portIdOptions
        } catch (e) {
            console.error('getPortIdTypeOptionsError:', e)
        }
    }
)