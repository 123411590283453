import { createAsyncThunk } from "@reduxjs/toolkit";

import Axios from "../../../../axiosSetup";
import { AxiosError } from "axios";

import queryString from "query-string";
import { toast } from "react-toastify";
import { AESEncrypt, AESEncryptforDashboard } from "../../../../encrypt-util";
import { AESDecrypt } from "../../../../encrypt-util";
import axiosInstance, { axiosCacheInstance } from "../../../../axiosSetup";
import moment from "moment";

const dateFormat = (date:string, format:string) => {
  return date? moment(date, format).format("YYYY-MM-DDTHH:mm:ss")+'+00:00' : ''
}

const dateDashboardFormat = (date:string, format:string) => {
  return date? moment(date, format).format("YYYY-MM-DDTHH:mm:ss")+'.000Z' : ''
}

export const getBlsList = createAsyncThunk(
  "dashboard/getAllList",
  async (seekQuery?: any | undefined) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?${queryString.stringify({
          ...seekQuery,
          size: 20,
        })}`
      );

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getBlsListExportCSV = createAsyncThunk(
  "dashboard/getBlsListExportCSV",
  async () => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl/sendBlCsvMail?size=1&sort=consigneeNm,ASC&direction=reverse${queryString.stringify(
          {
            size: 1000,
          }
        )}`
      );
      toast.success(response.data.message, {
        style: {
          color: "white",
        },
        toastId:'b7b7205'
      });

      return {};
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      return {};
    }
  }
);

export const getInvoiceListExportCSV = createAsyncThunk(
  "dashboard/getInvoiceListExportCSV",
  async () => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/invoice/emailCsv?size=1&sort=consigneeNm,ASC&direction=reverse${queryString.stringify(
          {
            size: 1000,
          }
        )}`
      );
      toast.success(response.data.message, {
        style: {
          color: "white",
        },
        toastId:'9500303'
      });
      return {};
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      return {};
    }
  }
);

export const getInvoiceList = createAsyncThunk(
  "invoicedashboard/getAllList",
  async (seekQuery?: any | undefined) => {
    try {
      const response = await Axios.post(
        `/api/invoice-management/v1/invoice/listInvoiceByQuery`,
        {
          ...seekQuery,
          limit: "20",
          sorts: seekQuery?.sort ? [seekQuery?.sort] : [],
          fields: [],
        }
      );
      if (seekQuery?.paidIndicator == "1") {
        const filteredData = {
          ...response.data,
          list: response.data.list.filter(
            (item: any) => item.paidIndicator === "1"
          ),
        };
        return filteredData;
      }
      if (response?.data?.list) {
        return response.data;
      }

      return {
        next: null,
        previous: null,
        list: [],
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInvoiceListFilter = createAsyncThunk(
  "invoicedashboard/getInvoiceListFilter",
  async (filterData: any) => {
    try {
      const filterString = queryString.stringify(
        {
          ...filterData,

          time: undefined,
        },
        { skipEmptyString: true }
      );

      const response = await Axios.post(
        `/api/invoice-management/v1/invoice/listInvoiceByQuery`,
        {
          filters: {},
          sorts: [],
          seek: "",
          direction: "",
          limit: "20",
          search: "",
        }
      );

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInvoiceListWithBlnumber = createAsyncThunk(
  "invoicedashboard/getInvoiceListWithBlnumber",
  async (blNumber: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/invoice?blNumber=${blNumber}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          blNumber: v?.blNumber || null,
          blId: v?.blId || null,
          invoiceNumber: v?.number || null,
          invoiceType: v?.type || null,
          end: v?.finalPod || null,
          time: v?.lastUpdateDate || null,
          consignee: v?.consigneeNm || null,
          vessel: v?.vesselName || null,
          due: v?.etd || null,
          pmt_reference: v?.paymentRefNo || null,
          paidIndicator: v?.paidIndicator || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInvoiceListWithSearch = createAsyncThunk(
  "invoicedashboard/getInvoiceListWithSearch",
  async (searchInputValue: any) => {
    try {
      const response = await Axios.post(
        "/api/invoice-management/v1/invoice/listInvoiceByQuery",
        {
          fields: [],
          filters: searchInputValue,

          sorts: [],
          limit: "20",
          search: "",
        }
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInvoiceListWithShipContainerNo = createAsyncThunk(
  "invoicedashboard/getInvoiceListWithShipContainerNo",
  async ({
    searchInputValue,
    type,
  }: {
    searchInputValue: string;
    type: string;
  }) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/coreor/${type}/invoice/${searchInputValue}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          number: v?.number || null,
          ccy: v?.ccy || null,
          invDate: v?.invDate || null,
          invoiceType: v?.type || null,
          blNumber: v?.blNumber || null,
          blId: v?.blId || null,
          end: v?.finalPod || null,
          time: v?.lastUpdateDate || null,
          consignee: v?.consigneeNm || null,
          instruction: v?.instructionStatus || null,
          release: v?.releaseStatus || null,
          vessel: v?.vesselName || null,
          due: v?.etd || null,
          amount: v?.amount || null,
          paidIndicator: v?.paidIndicator || null,
          dueDate: v?.dueDate || null,
          paymentRefNo: v?.paymentRefNo || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInvoiceTableData = createAsyncThunk(
  "invoiceChange/getInvoiceTableData",
  async (params: any) => {
    try {
      if(params)
      {
        const response = await axiosInstance.post(
          `/api/opc-rest-v1/v1/invoice/getInvoices`,
          {
            tpoId: params.tpoId,
            invoiceType: params.invoiceType,
            payeeId: params.payeeId,
          }
        );
  
        return (response.data && response.data?.length>0)?response.data.map((v:any) =>({
          ...v,
          effectiveDate: dateFormat(v?.effectiveDate, 'DD-MM-YYYY')
        })):[];
      }
      else{
        return [];
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getEnquiryTableData = createAsyncThunk(
  "invoiceChange/getEnquiryTableData",
  async ({ draftNumber }: any) => {
    try {
      const response = await axiosInstance.post(
        `/api/opc-rest-v1/v1/common/getInvoiceDetailsDashboard?draftNumber=${draftNumber}`
      );

      return (response?.data && response?.data?.length>0)?response?.data?.map((v:any)=>({
        ...v,
        paidThruDate: dateFormat(v?.paidThruDate, "YY-MMM-DD HHmm")
      })):[];
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return [];
    }
  }
);
export const getEnquiryTableDataForContainer = createAsyncThunk(
  "invoiceChange/getEnquiryTableDataForContainer",
  async ({ payload }: any) => {
    try {
      const response = await axiosInstance.post(
        `/api/opc-rest-v1/v1/common/getContainerDetailsDashboard?vesselKey=${payload.selectedVessel}&placeOfDelivery=${payload.selectedICDcode}`
      );

      return (response?.data && response.data.length > 0)? response.data.map((v:any)=>({
        ...v,
        timeIn: dateFormat(v?.timeIn, "DD-MM-YYYY")
      })): [];
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return [];
    }
  }
);
export const getVesselTableData = createAsyncThunk(
  "invoiceChange/getVesselTableData",
  async (params: any) => {
    try {
      const response = await axiosInstance.get(
        `/api/opc-rest-v1/v1/master/getVesselVoyageFromTicts`
      );

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getVesselMasterData = createAsyncThunk(
  "invoiceChange/getVesselMasterData",
  async (params: any) => {
    try {
      const response = await axiosInstance.get(
        `/api/opc-rest-v1/v1/master/getVesselVoyageById?id=${params}`
      );
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const saveVesselMasterData = createAsyncThunk(
  "invoiceChange/saveVesselMasterData",
  async (payload: any) => {
    try {
      const response = await axiosInstance.post(
        `/api/opc-rest-v1/v1/master/saveVesselVoyageDetails`,
        payload
      );
      //debugger;
      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getPermitTrackingData = createAsyncThunk(
  "invoiceChange/getPermitTrackingData",
  async (params: any) => {
    try {
      const response = await axiosInstance.post(
        `/api/opc-management/v1/permit/getEirOutPermits?tpoId=${params}`
      );

      return response?.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getPermitTracking = createAsyncThunk(
  "invoiceChange/getPermitTracking",
  async (params: any) => {
    try {
      const response = await axiosInstance.post(
        `/api/opc-rest-v1/v1/permit/getPermits`,
        {
          tpoId: params.tpoId,
          payeeId: params.payeeId,
          permitType: params.permitType,
        }
      );
      return (response?.data && response?.data?.length>0)?response?.data.map((v:any)=>({
        ...v,
        date: dateFormat(v?.date, "DD-MM-YYYY"),
        slotStartTime: dateFormat(v?.slotStartTime, 'DD-MM-YYYY HH:mm:ss'),
        slotEndTime: dateFormat(v?.slotEndTime, 'DD-MM-YYYY HH:mm:ss')
      })):[];
    } catch (e) {
      const error = e as AxiosError;

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getEbsPermitsData = createAsyncThunk(
  "invoiceChange/getEbsPermitsData",
  async (params: any) => {
    try {
      const response = await axiosInstance.post(
        `/api/opc-management/v1/permit/getEbsPermits?tpoId=${params}`
      );

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getEirInPermitsData = createAsyncThunk(
  "invoiceChange/getEirInPermitsData",
  async (params: any) => {
    try {
      const response = await axiosInstance.post(
        `/api/opc-management/v1/permit/getEirInPermits?tpoId=${params}`
      );

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getDashboardData = createAsyncThunk(
  "dashboard/getDashboardData",
  async (params: any, { getState }: any) => {
    const searchInputValue = getState().navbarTop?.searchInputValue;
    var whereClause = "";
    params.parameter.searchParameter = searchInputValue;
    if (
      params?.parameter?.searchWhere?.includes("#PARAM#") &&
      params?.parameter?.searchParameter
    ) {
      if (whereClause !== "") {
        whereClause = whereClause.concat(" AND ");
      }
      whereClause = whereClause.concat(
        (params?.parameter?.searchWhere as string).replaceAll(
          "#PARAM#",
          params?.parameter?.searchParameter
        )
      );
    }
    if (params.partnerId) {
      if (whereClause != "") {
        whereClause = whereClause.concat(" AND ");
      }
      whereClause = whereClause.concat(
        AESDecrypt(params.where)
          .toString()
          .replaceAll("#PARTNER_ID#", params.partnerId)
      );

      whereClause = whereClause.replaceAll(
        "#VIEW_NM#",
        "vw_" + params?.gridName?.toLowerCase()
      );
      if (params.where) {
        whereClause = whereClause.replaceAll("#PARAM#", params.dataCountry);
      }
    }

    if (!params.partnerId && params.where) {
      if (whereClause != "") {
        whereClause = whereClause.concat(" AND ");
      }
      whereClause = whereClause.concat(
        AESDecrypt(params.where)
          .toString()
          .replaceAll("#PARAM#", params.dataCountry)
      );
    }
    
    const filter:any={}; 

    Object.entries(params.payload.filters).forEach(([key,val]:any)=>{
      if(params.formatColumns && params.formatColumns.includes(key))
      {
        filter[key]=val.map((data:any)=>dateDashboardFormat(data,'YYYY-MM-DDTHH:mm:ss.SSSZ'))
      }
      else{
        filter[key]=val
      }
    })

    try {
      //  if (params.where != null || '') {
      /*  var view;
      if (window.location.pathname.includes('opc/instruction')) {
        view = 'E58NbJpkbNRw9+y/FKnz4+ZLO9CsHv/yAz6QBCjYEpM=';
      } else if (window.location.pathname.includes('opc/doupload')) {
        view = AESEncryptforDashboard('VW_OPC_DO_DASHBOARD'.toLowerCase());
      } else if (window.location.pathname.includes('opc/users')) {
        view = AESEncryptforDashboard('VW_OPC_USER_DASHBOARD'.toLowerCase());
      } else if (window.location.pathname.includes('opc/registrationmaster')) {
        view = AESEncryptforDashboard(
          'VW_OPC_REGISTRATION_DASHBOARD'.toLowerCase()
        );
      } else if (window.location.pathname.includes('opc/payment-fund')) {
        view = AESEncryptforDashboard('VW_OPC_PAYMENT_FUNDING'.toLowerCase());
      } else if (window.location.pathname.includes('opc/paymentDashboard')) {
        view = AESEncryptforDashboard('VW_OPC_PAYMENT_DASHBOARD'.toLowerCase());
      }
        */
      const response = await axiosInstance.post(`/api/opc-rest-v1/v1/view`, {
        ...params.payload,
        filters:filter, 
        view: AESEncryptforDashboard("vw_" + params?.gridName.toLowerCase()),
        where: AESEncryptforDashboard(whereClause),
        asCsv: params?.asCsv,
        //  view: 'E58NbJpkbNRw9+y/FKnz4+ZLO9CsHv/yAz6QBCjYEpM=',
        //  where: '',
        // asCsv: false,
      });
      if (response?.data?.result)
        return { gridName: params?.gridName, data: response.data };
      return {
        gridName: params?.gridName,
        data: {
          maxPage: null,
          size: null,
          result: [],
          asCsv: params?.asCsv,
        },
      };
      // }
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;

      return {
        gridName: params?.gridName,
        data: {
          maxPage: null,
          size: null,
          result: [],
        },
      };
    }
  }
);

export const getInstructionList = createAsyncThunk(
  "instructions/getInstructionList",
  async (seekQuery?: any | undefined) => {
    try {
      /* const response = await Axios.get(
        `/api/invoice-management/v1/invoice?${queryString.stringify({
          ...seekQuery,
          size: 20,
        })}`
      ); */

      const response = await Axios.post(
        `/api/invoice-management/v1/instructions/listInstructions`,
        {
          ...seekQuery,
          limit: "20",
          sorts: seekQuery?.sort ? [seekQuery?.sort] : [],
          fields: [],
        }
      );
      if (seekQuery?.paidIndicator == "1") {
        const filteredData = {
          ...response.data,
          list: response.data.list.filter(
            (item: any) => item.paidIndicator === "1"
          ),
        };
        return filteredData;
      } /* else if (seekQuery?.paidIndicator == '2') {
        const filteredData = {
          ...response.data,
          list: response.data?.list?.filter(
            (item: any) =>
              item.paidIndicator === '' || item.paidIndicator === null
          ),
        };

        return filteredData;
      }
 */
      if (response?.data?.list) {
        return response.data;
      }

      return {
        next: null,
        previous: null,
        list: [],
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInstructionListWithBlnumber = createAsyncThunk(
  "instructions/getInstructionListWithBlnumber",
  async (searchInputValue: any) => {
    try {
      const response = await Axios.post(
        `/api/invoice-management/v1/instructions/listInstructions`,
        {
          fields: [],
          filters: searchInputValue,

          sorts: [],
          limit: "20",
          search: "",
        }
      );
      if (response?.data?.list) return response.data;
      return {
        next: null,
        previous: null,
        list: [],
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getInstructionsListFilter = createAsyncThunk(
  "instructions/listInstructions",
  async (filterData?: any | undefined) => {
    let payloadString: any = {};
    for (let k of Object.keys(filterData)) {
      if (typeof filterData[k] && filterData[k]?.length > 1) {
        payloadString[k] = filterData[k].toString();
      } else {
        payloadString[k] = filterData[k];
      }
    }
    try {
      const response = await Axios.post(
        `/api/invoice-management/v1/instructions/listInstructions`,
        {
          ...filterData,
          limit: "20",
          sorts: filterData?.sort ? [filterData?.sort] : [],
          //sorts: filterData?.sort ? [filterData?.sort] : ["lastUpdatedDate,asc"],
          fields: [],
        }
      );
      if (response?.data?.list) return response.data;
      return {
        next: null,
        previous: null,
        list: [],
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const searchWithBlno = createAsyncThunk(
  "dashboard/searchWithBlno",
  async (blNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?blNo=${blNo}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          delegatedTo: v?.delegatedTo || null,
          paymentStatus: v?.paymentStatus || null,
          shipmentNo: v?.shipmentNo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ""}${
            v?.vesselName && v?.voyageNo ? " - " : ""
          }${v?.voyageNo || ""}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : "",

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);
export const searchWithShipmentNoBlDashboard = createAsyncThunk(
  "dashboard/searchWithShipmentNoBlDashboard",
  async (blNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?shipmentNo=${blNo}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          paymentStatus: v?.paymentStatus || null,
          delegatedTo: v?.delegatedTo || null,
          shipmentNo: v?.shipmentNo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ""}${
            v?.vesselName && v?.voyageNo ? " - " : ""
          }${v?.voyageNo || ""}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : "",

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);
export const searchWithInternalVoyageNo = createAsyncThunk(
  "dashboard/searchWithInternalVoyageNo",
  async (internalVoyageNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?internalVoyageNo=${internalVoyageNo}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          paymentStatus: v?.paymentStatus || null,
          shipmentNo: v?.shipmentNo || null,
          delegatedTo: v?.delegatedTo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ""}${
            v?.vesselName && v?.voyageNo ? " - " : ""
          }${v?.voyageNo || ""}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : "",

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const searchWithShipmentno = createAsyncThunk(
  "dashboard/searchWithShipmentno",
  async (blNo: any) => {
    try {
      const response = await Axios.get(`/api/invoice-management/v1/bl/${blNo}`);

      return {
        ...response.data,
        list: [response.data].map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          delegatedTo: v?.delegatedTo || null,
          instructionStatus: v?.instructionStatus || null,
          paymentStatus: v?.paymentStatus || null,
          shipmentNo: v?.shipmentNo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ""}${
            v?.vesselName && v?.voyageNo ? " - " : ""
          }${v?.voyageNo || ""}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : "",

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const searchWithContainerno = createAsyncThunk(
  "dashboard/searchWithContainerno",
  async (containerNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/coreor/container/${containerNo} `
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          paymentStatus: v?.paymentStatus || null,
          shipmentNo: v?.shipmentNo || null,
          delegatedTo: v?.delegatedTo || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ""}${
            v?.vesselName && v?.voyageNo ? " - " : ""
          }${v?.voyageNo || ""}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : "",

          type: v?.type || null,
          startpol: v?.startpol || null,
          finalpod: v?.finalpod || null,
          notifyParty: v?.notifyParty || null,
          lastUpdatedDate: v?.lastUpdatedDate || null,
          voyageNo: v?.voyageNo || null,
          transportType: v?.transportType || null,
          expModeOfTransport: v?.expModeOfTransport || null,
          impModeOfTransport: v?.impModeOfTransport || null,
          expHaulage: v?.expHaulage || null,
          impHaulage: v?.impHaulage || null,
          preInternalVoyageNo: v?.preInternalVoyageNo || null,
          preExternalVoyageNo: v?.preExternalVoyageNo || null,
          preVesselName: v?.preVesselName || null,
          mainInternalVoyageNo: v?.mainInternalVoyageNo || null,
          mainExternalVoyageNo: v?.mainExternalVoyageNo || null,
          mainVesselName: v?.mainVesselName || null,
          onInternalVoyageNo: v?.onInternalVoyageNo || null,
          onExternalVoyageNo: v?.onExternalVoyageNo || null,
          onVesselName: v?.onVesselName || null,
          blType: v?.type || null,
          consigneeCustomerCode: v?.consigneeCustomerCode || null,
          consigneeInternalCode: v?.consigneeInternalCode || null,
          shipperName: v?.shipperName || null,
          shipperCustomerCode: v?.shipperCustomerCode || null,
          shipperInternalCode: v?.shipperInternalCode || null,
          nomineeName: v?.nomineeName || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const updateVesselQuota = createAsyncThunk(
  "updateVesselQuota",
  async (payload: any) => {
    try {
      const response = await Axios.post(
        `/api/opc-rest-v1/v1/master/saveVesselVoyageQuota`,
        payload
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPaymentList = createAsyncThunk(
  "dashboard/getAllList",
  async (seekQuery?: any | undefined) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl?${queryString.stringify({
          ...seekQuery,
          size: 20,
        })}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,

          pol: v?.pol || null,
          pod: v?.pod || null,
          finalPod: v?.finalPod || null,
          lastUpdated: v?.lastUpdatedDate || null,
          consigneeNm: v?.consigneeNm || null,
          instructionStatus: v?.instructionStatus || null,
          releaseStatus: v?.releaseStatus || null,
          vesselName: `${v?.vesselName || ""}${
            v?.vesselName && v?.voyageNo ? " - " : ""
          }${v?.voyageNo || ""}`,
          due: v?.etd || null,
          eta:
            v?.eta && v?.etd
              ? `${v.eta}/${v.etd}`
              : v?.eta
              ? v.eta
              : v?.etd
              ? v.etd
              : "",
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);
export const searchWithInternalVoyageNoPaymentDashboard = createAsyncThunk(
  "invoicedashboard/searchWithInternalVoyageNoPaymentDashboard",
  async (internalVoyageNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/invoice/voyage/${internalVoyageNo}`
      );

      return {
        ...response.data,
        list: response.data.list.map((v: any) => ({
          ...v,
          blNumber: v?.blNumber || null,
          blId: v?.blId || null,
          invoiceNumber: v?.number || null,
          invoiceType: v?.type || null,
          end: v?.finalPod || null,
          time: v?.lastUpdateDate || null,
          consignee: v?.consigneeNm || null,
          vessel: v?.vesselName || null,
          due: v?.etd || null,
          pmt_reference: v?.paymentRefNo || null,
          paidIndicator: v?.paidIndicator || null,
        })),
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {
        next: null,
        previous: null,
        list: [],
      };
    }
  }
);

export const getBlByBlNo = createAsyncThunk(
  "bl/getBlByBlNo",
  async (blNo: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl/getBlByBlNo?blNo=${blNo}`
      );
      if (response?.data) {
        return response.data;
      } else {
        return {};
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {};
    }
  }
);

export const getUnassignedBlByBlNo = createAsyncThunk(
  "bl/getUnassignedBlByBlNo",
  async (payload: any) => {
    try {
      const response = await Axios.get(
        `/api/invoice-management/v1/bl/getUnassignedBlByBlNo?blNo=${payload?.blNo}&type=${payload.type}`
      );
      if (response?.data) {
        return response.data;
      } else {
        return {};
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);

      return {};
    }
  }
);

export const exportDashboardData = createAsyncThunk(
  "dashboard/exportDashboardData",
  async (params: any) => {
    var whereClause = "";

  const getName:any = {
      OPC_INSTRUCTION_DASHBOARD: 'Instruction',
      OPC_INVOICE_PMT_DASHBOARD:"Payment Report",
      OPC_DO_DASHBOARD: 'DO Dashboard',
      OPC_USER_DASHBOARD: 'Users',
      OPC_PAYMENT_DASHBOARD: 'Payment Details',
      OPC_PAYMENT_FUNDING: 'Payment Funding',
      OPC_BESTPICK_DASHBOARD:"Best Pick Assign",
      OPC_EXCHANGE_RATE:"Rate Of Exchange",
      OPC_REGISTRATION_DASHBOARD:"Registration Master",
      OPC_VESSEL_MASTER:"Vessel Master"
    };

    if (
      params?.parameter?.searchWhere?.includes("#PARAM#") &&
      params?.parameter?.searchParameter
    ) {
      if (whereClause !== "") {
        whereClause = whereClause.concat(" AND ");
      }

      whereClause = whereClause.concat(
        (params?.parameter?.searchWhere as string).replaceAll(
          "#PARAM#",
          params?.parameter?.searchParameter
        )
      );
    }
    if (params.partnerId) {
      if (whereClause != "") {
        whereClause = whereClause.concat(" AND ");
      }
      whereClause = whereClause.concat(
        AESDecrypt(params.where)
          .toString()
          .replaceAll("#PARTNER_ID#", params.partnerId)
      );

      whereClause = whereClause.replaceAll(
        "#VIEW_NM#",
        "vw_" + params?.gridName?.toLowerCase()
      );
    }

    if (!params.partnerId && params.where) {
      if (whereClause != "") {
        whereClause = whereClause.concat(" AND ");
      }
      whereClause = whereClause.concat(
        AESDecrypt(params.where)
          .toString()
          .replaceAll("#PARAM#", params.dataCountry)
      );
    }
    // debugger;
    try {
      // if (params.dataCountry != null) {
      const response = await Axios.post(`/api/opc-rest-v1/v1/view`, {
        ...params.payload,
        view: AESEncryptforDashboard("vw_" + params?.gridName?.toLowerCase()),
        where: AESEncryptforDashboard(whereClause),
        asCsv: params?.asCsv,
        dashboardName: getName[params.gridName]??''
      });

      // if (response?.data?.result) {
      //   toast.success('CSV file has been shared successfully to your email ID.');
      //   // toast('Mail has been Send Successfuly');
      // }
      if (response?.data?.result) return response?.data?.result;
      /*   if (response?.data?.result)
        return { gridName: params?.gridName, data: response.data };
      return {
        gridName: params?.gridName,
        data: {
          maxPage: null,
          size: null,
          result: [],
          asCsv: params?.asCsv,
        },
      }; */
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;

      /*  return {
        gridName: params?.gridName,
        data: {
          maxPage: null,
          size: null,
          result: [],
        },
      }; */
    }
  }
);
