import { faCreditCard, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, Form, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import bg1 from "../../../assets/images/landing-bg.jpeg";
import Divider from "../../../components/common/Divider";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import QuickPayModals from "./QuickPayModals";
import QuickPayTable from "./QuickPayTable";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Logo from "../../../assets/images/logo.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useDispatch } from "react-redux";
import {
  quickPayNumberError,
  resetQuickPayTable,
  setQuickPayRow,
} from "./quickPaySlice";
import { useLoading } from "../../../middleware/loadingMiddleware";
import { toast } from "react-toastify";

export default function QuickPay() {
  const [modals, setModals] = useState({
    toc: false,
    policy: false,
    registered: false,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const invData = useSelector((state: RootState) => state.quickpay.data);
  const dispatch = useDispatch<AppDispatch>();
  const countryOptions = useSelector(
    (state: RootState) => state.quickpay.countryList
  );
  const validationLoading = useLoading(["quickpay/validateInvoice"]);
  const linerOptions = useSelector(
    (state: RootState) => state.quickpay.linerList
  );
  const linerLoading = useLoading([
    "quickPay/linerOptions",
    "quickPay/getCountryList",
  ]);
  const countryLoading = useLoading(["quickPay/getCountryList"]);
  const validationSchema = Yup.object().shape({
    emailID: Yup.string()
      .email("Invalid Email Address")
      .required("Email address is a mandatory field"),
    country: Yup.string().required("Shipping country is a mandatory field"),
    liner: Yup.string().required("Liner is a mandatory field"),
    toc: Yup.boolean().equals([true], "Please accept to continue with payment"),
  });

  type FormShape = {
    emailID: string;
    country: string;
    liner: string;
    toc: boolean;
  };

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<FormShape>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    (async () => {
      if (countryOptions?.length) return;
      const countryList: any = [];
      setValue("country", countryList?.[0]?.value ?? undefined);
    })();
    setValue("emailID", "");
    setValue("toc", false);
  }, []);

  useEffect(() => {
    (async () => {
      if (watch("country")) {
        const linerList: any = [];
        setValue("liner", linerList?.[0]?.value ?? undefined);
      }
    })();
  }, [watch("country")]);

  const onSubmit = handleSubmit(
    (payload) => {
      const tableError = invData.filter(
        (i) =>
          i.invoiceAmount == null ||
          i.invoiceNo == "" ||
          i.invoiceCurrency == "" ||
          Object.values(i.error).find((x) => x) ||
          !i.valid
      );
      const duplicateInvId = invData.filter(
        (value, index, self) =>
          self.findIndex((n) => n.invoiceNo == value.invoiceNo) !== index
      );
      const total = invData.reduce((a, b) => a + (b.invoiceAmount ?? 0), 0);
      if (tableError.length > 0) {
        tableError.forEach((row) => {
          dispatch(
            setQuickPayRow({
              rowNumber: row.rowNumber,
              error: {
                ...row.error,
                invoiceNo:
                  row.error.invoiceNo || row.invoiceNo == "" || !row.valid,
                invoiceAmount:
                  row.error.invoiceAmount || !row.invoiceAmount || !row.valid,
                invoiceCurrency:
                  row.error.invoiceCurrency ||
                  row.invoiceCurrency == "" ||
                  !row.valid,
              },
            })
          );
          if (!row.valid && !validationLoading) {
            toast(
              "Please enter valid invoice no and/or amount and/or currency"
            );
          }
        });
      } else if (duplicateInvId.length > 0) {
        dispatch(quickPayNumberError(duplicateInvId));
        toast("Duplicate Invoice entries detected");
      } else if (total < 0) {
        toast.error("Total Payable Amount can not be negative", {
          toastId: "183fe63",
        });
      } else {
        navigate("pay", { state: { ...payload } });
      }
    },
    (error) => {
      console.error(error);
    }
  );

  return (
    <div
      className="d-flex overflow-hidden justify-content-center 
    align-content-center flex-row"
      style={{ height: "100vh" }}
    >
      <div
        className="col-lg-12"
        style={{
          height: "100vh",
          backgroundImage: `url(${bg1})`,
          backgroundSize: "100%",
          backgroundPosition: "45% 100%",
          filter: "brightness(75%)",
          position: "absolute",
        }}
      />

      <div style={{ top: "15px", left: "125px", position: "absolute" }}>
        <Link to={"/"}>
          <Image
            src={Logo}
            width={120}
            height="auto"
            style={{ position: "absolute" }}
          />
        </Link>
      </div>

      <Card className="col-lg-5 p-3 mt-3 mb-3 overflow-auto">
        {location.pathname.includes("/pay") ? (
          <Outlet />
        ) : (
          <>
            <div className="d-flex w-100 justify-content-between">
              <h3 className="fs-1">Quick Pay</h3>
              <Link
                to={""}
                onClick={() => {
                  // react router does not work since auth server is
                  // outside of this react project
                  window.location.href = "/opc-auth/login";
                }}
                className="mx-2"
              >
                <u>
                  <i>Login</i>
                </u>
              </Link>
            </div>
            <Divider></Divider>
            <Form onSubmit={onSubmit}>
              <Form.Group className="mb-3">
                <span className="mandatory">*</span>
                <Form.Label controlId="floatingInput">Email address</Form.Label>
                <Form.Control
                  placeholder={"Email address"}
                  name="username"
                  type="email"
                  className={`fs--1 form-control ${
                    errors.emailID ? "is-invalid" : ""
                  }`}
                  value={watch("emailID")}
                  onChange={(e) => {
                    clearErrors("emailID");
                    setValue("emailID", e.target.value?.trim?.());
                  }}
                />
                {errors.emailID && (
                  <div className="fs--1 is-invalid mandatory m-0 p-0">
                    {errors.emailID.message}
                  </div>
                )}
              </Form.Group>
              <div className="d-flex flex-col justify-content-between">
                <Form.Group className="mb-3 me-3 flex-1">
                  <span className="mandatory">*</span>
                  <Form.Label controlId="floatingInput">
                    Shipping Country
                  </Form.Label>
                  <Form.Select
                    placeholder={"Select Country"}
                    name="country"
                    className={`fs--1 form-control ${
                      errors.country ? "is-invalid" : ""
                    } 
                                ${countryLoading ? "loadingPulse" : ""}`}
                    value={watch("country")}
                    onChange={(e) => {
                      clearErrors("country");
                      setValue("country", e.target.value);
                    }}
                    disabled={
                      countryLoading || (countryOptions?.length ?? 0) <= 2
                    }
                  >
                    {countryOptions?.map?.((x: any) => (
                      <option value={x.value} key={x.value}>
                        {x.text}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.country && (
                    <div className="fs--1 is-invalid mandatory m-0 p-0">
                      {errors.country.message}
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 flex-1">
                  <span className="mandatory">*</span>
                  <Form.Label controlId="floatingInput">Liner</Form.Label>
                  <Form.Select
                    placeholder={"Select Liner"}
                    name="liner"
                    className={`fs--1 form-control ${
                      errors.liner ? "is-invalid mb-0" : ""
                    } 
                                ${linerLoading ? "loadingPulse" : ""}`}
                    value={watch("liner")}
                    onChange={(e) => {
                      clearErrors("liner");
                      setValue("liner", e.target.value);
                    }}
                    disabled={linerLoading || linerOptions?.length < 2}
                  >
                    {linerOptions?.map((x: any) => (
                      <option value={x.value}>{x.text}</option>
                    ))}
                  </Form.Select>
                  {errors.liner && (
                    <div className="fs--1 is-invalid mandatory m-0 p-0">
                      {errors.liner.message}
                    </div>
                  )}
                </Form.Group>
              </div>

              <QuickPayTable country={watch("country")} />

              <Form.Check
                id="registration.acceptTerms"
                label={
                  <div>
                    <span>{t("resource_3:iAcceptThe")} </span>
                    <Link
                      onClick={() => {
                        setModals((state) => ({ ...state, toc: true }));
                      }}
                      to={""}
                    >
                      {t("resource_1:terms").toLowerCase()}
                    </Link>
                    <span> {t("resource_1:and").toLowerCase()} </span>
                    <Link
                      onClick={() => {
                        setModals((state) => ({ ...state, policy: true }));
                      }}
                      to={""}
                    >
                      {t("resource_2:privacyPolicy").toLowerCase()}
                    </Link>
                    {errors.toc && (
                      <span className="fs--1 is-invalid mandatory">
                        <br />
                        {errors.toc.message}
                      </span>
                    )}
                  </div>
                }
                checked={watch("toc")}
                onChange={({ target }) => {
                  clearErrors("toc");
                  setValue("toc", target.checked);
                }}
              />

              <div className="justify-content-center align-content-center d-flex">
                <Button
                  className="me-2"
                  variant="falcon-primary"
                  size="sm"
                  type="submit"
                  disabled={linerLoading || countryLoading || validationLoading}
                >
                  <FontAwesomeIcon icon={faCreditCard} className="me-2" />
                  {t("resource_1:pay")}
                </Button>
                <Button
                  className="me-2"
                  variant="falcon-primary"
                  size="sm"
                  onClick={() => {
                    clearErrors();
                    setValue("emailID", "");
                    setValue("toc", false);
                    setValue(
                      "country",
                      countryOptions?.[0]?.value ?? undefined
                    );
                    dispatch(resetQuickPayTable());
                  }}
                >
                  <FontAwesomeIcon icon={faRedo} className="me-2" />
                  {t("resource_1:reset")}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Card>
      <QuickPayModals
        toc={modals.toc}
        setToc={(x: boolean) => setModals((state) => ({ ...state, toc: x }))}
        policy={modals.policy}
        setPolicy={(x: boolean) =>
          setModals((state) => ({ ...state, policy: x }))
        }
        registered={modals.registered}
        setRegistered={(x: boolean) =>
          setModals((state) => ({ ...state, registered: x }))
        }
      />
    </div>
  );
}
