import { createAsyncThunk } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import axiosInstance from "../../../axiosSetup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { profile } from "console";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../../common/actions/userDetails";
import { Modal } from "react-bootstrap";
import TermsAndConditions from "./termsAndCondition";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
interface IProps {
  hasLabel: any;
}

const ResetPass = ({ hasLabel }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getUserProfile())
      .unwrap()
      .then((response: any) => {
        setIsTNCAccepted(
          (response?.data?.isTNCAccepted !== null &&
            response?.data?.isTNCAccepted === "Y") ||
            response?.data?.partnerType?.toString()?.toLowerCase() === "liner"
            ? true
            : false
        );
        setValue(
          "isTNCAccepted",
          (response?.data?.isTNCAccepted !== null &&
            response?.data?.isTNCAccepted === "Y") ||
            response?.data?.partnerType?.toString()?.toLowerCase() === "liner"
            ? true
            : false
        );
        setShow(
          !((profileDetails?.isTNCAccepted !== null &&
            profileDetails?.isTNCAccepted === "Y") ||
          profileDetails?.partnerType?.toString()?.toLowerCase() === "liner"
            ? true
            : false)
        );
      });
  }, []);

  type FormValues = {
    email: any;
    oldpassword: string;
    newpassword: string;
    confirmPassword: string;
    isTNCAccepted: boolean;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email ID is required"),
    oldpassword: Yup.string().required("Old Password is required"),
    newpassword: Yup.string()
      .required("New Password is required")
      .min(8, "Password length should be minimum 8 Character")
      .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character")
      .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character")
      .matches(/^(?=.*[0-9])/, "Must contain at least one number"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("newpassword"), null],
        "New and Confirm Password must match"
      )
      .required("Confirm Password is required")
      .label("Confirm password")
      .required(),
    isTNCAccepted: Yup.boolean().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
  });

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const [email, setEmail] = useState(profileDetails.email?.toString());

  const [oldpassword, setOldpassword] = useState<any>("");
  const [newpassword, setNewpassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");
  const [isTNCAccepted, setIsTNCAccepted] = useState<boolean>(
    (profileDetails?.isTNCAccepted !== null &&
      profileDetails?.isTNCAccepted === "Y") ||
      profileDetails?.partnerType?.toString()?.toLowerCase() === "liner"
      ? true
      : false
  );

  useEffect(() => {
    setEmail(profileDetails.email?.toString());
    setValue("email", profileDetails?.email?.toString());

    setIsTNCAccepted(
      (profileDetails?.isTNCAccepted !== null &&
        profileDetails?.isTNCAccepted === "Y") ||
        profileDetails?.partnerType?.toString()?.toLowerCase() === "liner"
        ? true
        : false
    );
    setValue(
      "isTNCAccepted",
      (profileDetails?.isTNCAccepted !== null &&
        profileDetails?.isTNCAccepted === "Y") ||
        profileDetails?.partnerType?.toString()?.toLowerCase() === "liner"
        ? true
        : false
    );
  }, [profileDetails]);

  const app = window.location.href.includes("tpdcs")
    ? ".tpdcs.com"
    : ".odexglobal.com";
  const authLink = window.location.href.includes("tpdcs") ? "opc-auth" : "auth";
  const windowLoc = window.location.href.indexOf(app);
  const windowurl = window.location.href.substring(0, windowLoc);

  const onSubmit = handleSubmit(
    async (payload) => {
      try {
        const response = await axiosInstance.post(
          "/api/user-management/v1/users/changePassword",
          {
            emailId: payload?.email,
            oldPassword: payload?.oldpassword,
            newPassword: payload?.newpassword,
            confirmPassword: payload?.confirmPassword,
            isTNCAccepted: payload.isTNCAccepted ? "Y" : "N",
          }
        );

        toast.dark(response.data.responseMsg, {
          toastId: "6c224f9",
        });
        if (response.data.responseCode == "SUCCESS") {
          {
            try {
              setTimeout(() => {
                window.location.href = `${windowurl}${app}/${authLink}/do/logout`;
              }, 1000);
            } catch (error) {
              toast.error("Unable to logout", {
                toastId: "50d34c7",
              });
              console.error("logoutError: ", error);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    (error: any) => {
      console.error("Handle submit error: ", error);
    }
  );

  const [show, setShow] = useState(
    !((profileDetails?.isTNCAccepted !== null &&
      profileDetails?.isTNCAccepted === "Y") ||
    profileDetails?.partnerType?.toString()?.toLowerCase() === "liner"
      ? true
      : false)
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseMessage = () => {
    if (isTNCAccepted) {
      handleClose();
    } else {
      handleShow();
      trigger("isTNCAccepted");
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3 mt-2">
          {hasLabel && (
            <Form.Label controlId="floatingInput">Email address</Form.Label>
          )}
          <Form.Control
            placeholder={!hasLabel ? "Email address" : ""}
            type="email"
            value={email}
            disabled={true}
            {...register("email")}
            className={`fs--1 inputBorder form-control ${
              errors.email ? "is-invalid" : ""
            }`}
            onChange={({ target }) => {
              setEmail(target.value);
            }}
            onBlur={(e) => {
              if (email !== "") {
                setValue("email", e.target.value);
              }
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Old Password" : ""}
            type="password"
            {...register("oldpassword")}
            className={`fs--1 inputBorder form-control ${
              errors.oldpassword ? "is-invalid" : ""
            }`}
            onChange={({ target }) => {
              setOldpassword(target.value);
            }}
            onBlur={(e) => {
              if (oldpassword !== "") {
                setValue("oldpassword", e.target.value);
              }
            }}
          />
          <div className="invalid-feedback">
            {errors.oldpassword?.message?.toString()}
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "New Password" : ""}
            {...register("newpassword")}
            className={`fs--1 inputBorder form-control ${
              errors.newpassword ? "is-invalid" : ""
            }`}
            type="password"
            onChange={({ target }) => {
              setNewpassword(target.value);
            }}
            onBlur={(e) => {
              if (newpassword !== "") {
                setValue("newpassword", e.target.value);
              }
            }}
          />
          <div className="invalid-feedback">
            {errors.newpassword?.message?.toString()}
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Confirm Password" : ""}
            {...register("confirmPassword")}
            className={`fs--1 inputBorder form-control ${
              errors.confirmPassword ? "is-invalid" : ""
            }`}
            type="password"
            onChange={({ target }) => {
              setConfirmPassword(target.value);
            }}
            onBlur={(e) => {
              if (confirmPassword !== "") {
                setValue("confirmPassword", e.target.value);
              }
            }}
          />
          <div className="invalid-feedback">
            {errors.confirmPassword?.message?.toString()}
          </div>
        </Form.Group>

        <input type="hidden" name="_csrf" />
        <div className="row">
          <div className="d-flex justify-content-center align-items-center col-12">
            <Form.Group className="text-center">
              <Button
                onSubmit={onSubmit}
                type="submit"
                color="danger"
                className="mt-2 w-100 btn-danger "
              >
                Reset
              </Button>
            </Form.Group>
          </div>
        </div>
        {
          <Modal
            show={show}
            onHide={handleClose}
            className="modal-xl"
            backdrop="static"
          >
            <Modal.Header className="p-2 pb-1" closeButton>
              <Modal.Title>{t("resource_3:termsAndConditions")}</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                height: "500px",
                overflow: "auto",
                fontSize: "12px",
              }}
            >
              <TermsAndConditions />
            </Modal.Body>
            <div
              className="p-2 pb-1 modal-footer"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="row vertical-center-row h6">
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label={
                      <div>
                        <span>{t("resource_3:iAcceptThe")} </span>
                        <Link to={""}>
                          {t("resource_1:terms").toLowerCase()}
                        </Link>
                        <span> {t("resource_1:and").toLowerCase()} </span>
                        <Link to={""}>
                          {t("resource_2:privacyPolicy").toLowerCase()}
                        </Link>
                      </div>
                    }
                    {...register("isTNCAccepted")}
                    className={` ${errors.isTNCAccepted ? "is-invalid" : ""}`}
                    onChange={({ target }) => {
                      setIsTNCAccepted(target.checked);
                      setValue("isTNCAccepted", target.checked);
                    }}
                    checked={isTNCAccepted}
                  />
                  <div className="invalid-feedback">
                    {errors.isTNCAccepted?.message?.toString()}
                  </div>
                </Form.Group>
              </div>
            </div>
            <div
              className="flex-container"
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                className="btn btn-primary m-2"
                onClick={handleCloseMessage}
              >
                Accept
              </Button>
            </div>
          </Modal>
        }
      </Form>
    </>
  );
};

export default ResetPass;
