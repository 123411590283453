/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

import queryString from 'query-string';
import { Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppDispatch, RootState } from '../../../../store';
import { getDashboardData } from '../../actions/dashboard';
import DataTable from '../../../../common/components/DataTable';
import FilterView from '../../../../common/components/FilterView';
import { AESDecrypt, AESEncrypt } from '../../../../../encrypt-util';
import { IDataTableColumn } from '../../../../common/types/dataTable';
import { updateInstructionID } from '../../../blDetails/slices/documentHistorySlice';
import { generateColumnConfig, IConfigsDefinition } from '../../../../utils/columnConfig';
import { getExchangeRate, getExchangeRateRefresh } from '../../../blPayment/actions/payments';
import { toggleAddAgent, toggleAddAgentButton, toggleColumnSettingsDashboard, updateCurrentPageInstructionDashboard } from '../../slices/dashboardSlice';

const TableComponent = () => {
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  const dispatch = useDispatch<AppDispatch>();
  const tableRef = useRef<any>(null);
  const { userID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const grids = useSelector((state: RootState) => state.dataTable.roeGrids);
  const currentPage = useSelector((state: RootState) => state.dashboard.currentPageInstruction);
  const tpoId = useSelector((state: RootState) => state.agentDelegation.tpoId);
  const searchInputValue = useSelector((state: RootState) => state.navbarTop.searchInputValue);
  const roeGrids = useSelector((state: RootState) => state.dataTable.roeGrids);
  const rbac = useSelector((state: RootState) => state.userProfile);
  const selectionInitialState = useSelector((state: RootState) => state.dashboard.selectedInstDataRows);
  const selectedTopSearchFilter = useSelector((state: RootState) => state.navbarTop.selectedTopSearchFilter);
  const data = useSelector((state: RootState) => state.dashboard.roeDashboardList);
  const profileDetails = useSelector((state: RootState) => state.userProfile.profile);
  const linerDataCountry = useSelector((state: RootState) => state.userProfile.linerDataCountry);
  const dataLoading = useSelector((state: RootState) => state.dashboard.dataLoading);
  const selectedData = useSelector((state: RootState) => state.dashboard.selectedData);
  const showColumnSettings = useSelector((state: RootState) => state.instructionDashboard.showColumnSettings);

  const sort = (queryString.parse(location.search)?.sort as string)?.split?.(",")?.join?.(" ");
  const storedUserCountry = JSON.parse(window.localStorage.getItem('@user-selected-datacountry') || '{}');
  const storedCountry = storedUserCountry?.[0]?.loginId === profileDetails.email ? storedUserCountry?.[0]?.country : null;
  const dataCountry: any = storedCountry ? storedCountry : linerDataCountry ? linerDataCountry : profileDetails.partnerCountry || null;

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInstructionDashboard(page));
  };

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsDashboard(false));
  };

  const getParams = () => {
    dispatch(
      updateInstructionID(AESDecrypt(location?.pathname?.split?.('/')?.[3]))
    );
    return {
      bl_id: userID ? AESDecrypt(userID) : '',
      doc_id: userID ? AESDecrypt(userID) : '',
      inst_id: userID ? AESDecrypt(userID) : '',
    };
  };

  const onClickRefreshRoeDashboard = () => {
    setTimeout(() => {
      dispatch(getExchangeRateRefresh("USD"));
      dispatch(
        getDashboardData({
          gridName: "OPC_EXCHANGE_RATE",
          payload: {
            fields: [],

            filters: /*   selectedTopSearchFilter?.where === ''
              ? instructionTopSearchState
              :  */ {
              from_ccy: ["USD"],
              exchange_to: ["TZS"],
            },
            size: 20,
            sorts: sort ? [sort] : ["exchange_rate_date desc"],
            page: currentPage,
          },
          dataCountry: dataCountry,
          partnerId: profileDetails.partnerId,

          where: roeGrids?.[0]?.whereClause
            ? AESEncrypt(roeGrids?.[0]?.whereClause)
            : null,
          parameter:
            selectedTopSearchFilter?.where !== ""
              ? {
                searchParameter: searchInputValue,
                searchWhere: selectedTopSearchFilter?.where,
              }
              : {},
        })
      );
      if (tpoId) {
        dispatch(getExchangeRate({ tpoIdval: tpoId, selectedCcy: "usd" }));
      }
    }, 150)
  }

  const additionChildrenRoe = (() => {
    return (
      <Button
        className=""
        variant="falcon-primary"
        size="sm"
        onClick={onClickRefreshRoeDashboard}
      >
        <FontAwesomeIcon icon={faRefresh} />
      </Button>
    )
  })()

  useEffect(() => {
    const blDashboardDefinition = grids?.[0]?.columnConfigGrid || '';

    if (!blDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(blDashboardDefinition)),
        getParams,
        navigate,
        selectedData,
        dispatch
      );

      setConfigs(configs);
      setColumns(configs?.columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location, selectedData]);

  useEffect(() => {
    const sliderOpened = true;

    if (sliderOpened && tableRef.current) {
      const tableElement = tableRef.current;
      const rowIndexToShow = 0;

      const rows = tableElement.getElementsByTagName('tr');
      const rowToScroll = rows[rowIndexToShow];

      if (rowToScroll) {
        const tableBounds = tableElement.getBoundingClientRect();
        const rowBounds = rowToScroll.getBoundingClientRect();

        if (
          rowBounds.bottom > tableBounds.bottom ||
          rowBounds.top < tableBounds.top
        ) {
          // Scroll the row into view smoothly
          rowToScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [location]);

  return (
    <>
      <FilterView
        showColumnSettings
        toggleColumnSettings={toggleColumnSettingsDashboard}
        showExport
        additionalChildren={additionChildrenRoe}
        columnSettingsPersistKey='@odex/maintable/v6'
        uniqueFilterKey='roeDashboard'
        dataLoading={dataLoading}
        //  configViews={grids?.find?.((g) => g?.gridId === 1)?.configGroup || []}
        configViews={
          grids?.find?.(
            (g) => g?.gridName === 'OPC_ROE_DASHBOARD'
          )?.configGroup || []
        }
        dashboardName='OPC_ROE_DASHBOARD'
        gridDef={grids}
        data={data}
      />
      {columns?.length > 0 && (
        <DataTable
          data={data?.result || []}
          columns={columns}
          sortable={false}
          searchable={false}
          showColumnFilter={false}
          selection={
            rbac.rbacDetails.allowInstructionAssignment == 'Y'
          }
          selectionInitialState={selectionInitialState}
          perPage={20}
          pagination
          configGroup={
            //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
            grids?.find?.(
              (g) => g?.gridName === 'OPC_ROE_DASHBOARD'
            )?.configGroup || []
          }
          onChangePageNumber={(pageNumber) =>
            setCurrentPage(pageNumber)
          }
          numberPagination
          maxPage={parseInt(data?.maxPage || '100')}
          hasNext={data?.hasNext}
          showExport={false}
          currentPage={currentPage}
          localFilter={true}
          showColumnSettings={showColumnSettings}
          gridName='OPC_ROE_DASHBOARD'
          onHide={onHideColumnSettings}
          columnSettingsPersistKey='@odex/maintable/v6'
          onCustomSelectChange={(d) => {
            if (d?.list?.length > 0) {
              dispatch(toggleAddAgentButton(true));
            } else {
              dispatch(toggleAddAgentButton(false));
              dispatch(toggleAddAgent(false));
            }
          }}
          customSort={true}
          shortPrepend='bl_'
          uniqueFilterKey='roeDashboard'
          staticHiddenCols={configs?.staticHiddenCols || []}
          defaultHiddenCols={configs?.defaultHiddenCols || []}
          autoHeight={false}
          tableMinHeight={400}
          dataCountry={dataCountry}
          gridId={grids?.[0]?.gridId || 0}
          dataLoading={false}
        />)}
    </>
  );
};

export default TableComponent;
