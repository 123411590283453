import { createAsyncThunk } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import axiosInstance from "../../../axiosSetup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { profile } from "console";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../../common/actions/userDetails";
import { Modal } from "react-bootstrap";
import TermsAndConditions from "./termsAndCondition";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
interface IProps {
  hasLabel: any;
}

const ForgotPass = ({ hasLabel }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  type FormValues = {
    email: any;
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email ID is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const [email, setEmail] = useState(profileDetails.email?.toString());
  const navigate = useNavigate();
  const windowLoc = window.location.href.indexOf(".tpdcs.com");
  const windowurl = window.location.href.substring(0, windowLoc);

  const onSubmit = handleSubmit(
    async (payload) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/users/forgotPassword/${payload?.email}`
        );

        toast.dark(response.data.responseMsg, {
          toastId: "a1578ca",
        });

        if (response.data.responseCode === "SUCCESS") {
          setTimeout(() => {
            window.location.href =
              `${process.env.REACT_APP_AUTH_SERVER_HOST}/login` ||
              `${window.location.hostname}/opc-auth/login`;
          }, 1000);
        }

        window.location.href = `${windowurl}.tpdcs.com/opc-auth`;
      } catch (error) {
        console.error(error);
      }
    },
    (error: any) => {
      console.error("Handle submit error: ", error);
    }
  );

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3 mt-2">
          {hasLabel && (
            <Form.Label controlId="floatingInput">Email address</Form.Label>
          )}
          <Form.Control
            placeholder={!hasLabel ? "Email address" : ""}
            type="email"
            value={email}
            {...register("email")}
            className={`fs--1 inputBorder form-control ${
              errors.email ? "is-invalid" : ""
            }`}
            onChange={({ target }) => {
              setEmail(target.value.toLowerCase());
            }}
            onBlur={(e) => {
              if (email !== "") {
                setValue("email", e.target.value);
              }
            }}
          />
        </Form.Group>
        <input type="hidden" name="_csrf" />
        <div className="row">
          <div className="d-flex justify-content-center align-items-center col-12">
            <Form.Group className="text-center">
              <Button onSubmit={onSubmit} type="submit" className="mt-2 w-100">
                Submit
              </Button>
            </Form.Group>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ForgotPass;
