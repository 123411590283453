import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useEffect, useState, useTransition } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  updateAmountMode,
  updatePaymentMode,
} from "../blPayment/slices/blPaymentSlice";
import { useDispatch } from "react-redux";
import { StandaloneInvoiceDetails } from "./slices/standaloneInvoicePaymentSlice";
import {
  getCurrencyDDList,
  getExchangeRate,
  getPaymentBanks,
} from "./actions/payments";
import { Item } from "react-bootstrap/lib/Breadcrumb";
import useDataCountry from "../../../hooks/useDataCountry";
import { tpoId } from "../blRequestInstruction/components/instructionSlice/AgentNominationSlice";
import { updatePartnerBankAccountDetails } from "../payment/paymentSlice";

const PayableAmount = ({
  settotalSelectedAmount,
  totalSelectedAmount,
  setselectedcurrency,
  selectedcurrency,
  setCurrencyDetails,
  setSelectedBeneficiary,
  selectedBeneficiary,
  currencyDetail,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const PartnerBankAccountDetails: any[] = useSelector(
    (state: RootState) => state.payment.PartnerBankAccountDetails
  );
  const tpoid = useSelector((state: RootState) => state.agentDelegation.tpoId);
  const tpoIdval = useSelector(
    (state: RootState) => state.agentDelegation.tpoId
  );

  const selectedRowsInvoice: any[] = useSelector((state: RootState) =>
    Object.values(state.multiBlPayment.selectedRowsInvoice).filter(
      (x) => x != undefined
    )
  );

  const dataCountry = useDataCountry();
  const location = useLocation();
  const matchPth =
    matchPath(
      {
        path: "/bl/:blNum/payment",
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: "instruction/bl/:blNum/payment",
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: "payment/bl/:blNum/payment",
        caseSensitive: true,
        end: true,
      },
      location.pathname
    );
  const disableFields =
    profileDetails?.partnerType === "liner" ||
    (matchPth && profileDetails?.partnerType !== "liner")
      ? true
      : false;
  const [selectedCcy, setSelectedCcy] = useState("usd");
  // const [selectedcurrency,setselectedcurrency] = useState<any>('USD')
  // const [totalSelectedAmount,settotalSelectedAmount] = useState<any>([])

  const selectedDocHistory: any = useSelector(
    (state: RootState) => state.documentHistory.data
  );

  const standaloneInvoiceList = useSelector(
    (state: RootState) => state.standaloneInvoice.data
  );
  const paymentMode = useSelector(
    (state: RootState) => state.blPayment.paymentMode
  );
  const pymtModeOptions = useSelector(
    (state: RootState) => state.blPayment.pymtModeOptions
  );

  const countryDDList = useSelector(
    (state: RootState) => state.standaloneInvoice.countryDDList
  );
  const newStandaloneInvoiceList: any = standaloneInvoiceList.map(
    (invoice: StandaloneInvoiceDetails) => {
      return {
        amount: invoice.invoiceAmount,
        id: invoice.invoiceNo,
        invID: invoice.invoiceId,
        invcurr: invoice.invoiceCurrency,
        ccy: invoice.invoiceCurrency,
      };
    }
  );

  useEffect(() => {
    console.log("payableAmunt", selectedcurrency, tpoid);

    dispatch(getPaymentBanks({ ccy: selectedcurrency, tpoId: tpoid }))
      .unwrap()
      .then((response: any) => {
        dispatch(updatePartnerBankAccountDetails(response));
        setCurrencyDetails(response[0]?.accountCurrency);
        setSelectedBeneficiary(response[0]?.value);
        console.log(
          "payableAmounthere123",
          selectedBeneficiary,
          currencyDetail
        );
      });
    // console.log("payableAmounthere123", PartnerBankAccountDetails);
  }, [selectedcurrency]);

  useEffect(() => {
    dispatch(getCurrencyDDList());
  }, []);

  useEffect(() => {
    if (selectedRowsInvoice && selectedRowsInvoice.length > 0) {
      const totalAmountLocal = selectedRowsInvoice.reduce(
        (sum, item) => sum + parseFloat(item.owed),
        0
      );
      const totalAmountTZS = selectedRowsInvoice.reduce(
        (sum, item) => sum + parseFloat(item.amountLocal),
        0
      );

      const selectedCurrency = selectedRowsInvoice[0]?.ccy;
      console.log("selectedCurrency", selectedCurrency);
      const updatedArray = [
        {
          currencyCode: countryDDList?.find(
            (data) => data?.currencyCode !== selectedCurrency
          )?.currencyCode,
          amount: totalAmountTZS.toFixed(2),
        },
        {
          currencyCode: selectedCurrency,
          amount: totalAmountLocal.toFixed(2),
        },
      ];

      // Check if the new array is different from the previous state
      if (
        JSON.stringify(updatedArray) !== JSON.stringify(totalSelectedAmount)
      ) {
        settotalSelectedAmount(updatedArray);
      }
    } else {
      const updatedArray =
        countryDDList?.map((data) => ({
          currencyCode: data?.currencyCode,
          amount: 0,
        })) || [];

      // Check if the new array is different from the previous state
      if (
        JSON.stringify(updatedArray) !== JSON.stringify(totalSelectedAmount)
      ) {
        settotalSelectedAmount(updatedArray);
      }
    }
  }, [selectedRowsInvoice, countryDDList, totalSelectedAmount]);

  return (
    <Form.Group as={Row} controlId="payableAmount.input">
      <Form.Label column lg={5} className="fs--1">
        {t("resource_2:payableAmount")}
      </Form.Label>
      <Col lg={6}>
        <InputGroup>
          {false ? (
            <div className="d-flex py-1">
              {totalSelectedAmount.map((item: any) => (
                <div className="pe-5">
                  <p className="text-600 fs--1 fw-medium mb-0">
                    {item.currencyCode}
                  </p>{" "}
                  <h4 className="text-800 mb-0">{item.amount} </h4>
                </div>
              ))}
            </div>
          ) : (
            <>
              <Col lg={5}>
                <Form.Select
                  value={selectedcurrency}
                  onChange={(e) => {
                    setselectedcurrency(e.target.value);
                    if (
                      e.target.value != "USD" &&
                      [2, 4].includes(+paymentMode)
                    ) {
                      dispatch(
                        updatePaymentMode(
                          pymtModeOptions.filter(
                            ({ value }: any) => ![2, 4].includes(value)
                          )[0].value
                        )
                      );
                    }
                  }}
                  // disabled={true}
                >
                  {totalSelectedAmount.map((item: any) => (
                    <option value={item.currencyCode}>
                      {item.currencyCode}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              {/*   7 */}
              <Col lg={4}>
                <Form.Control
                  aria-label={t("resource_2:payableAmount")}
                  className="text-end"
                  aria-describedby="payable-amount"
                  value={
                    (
                      totalSelectedAmount.find(
                        (item: any) => item.currencyCode === selectedcurrency
                      ) || { amount: "" }
                    ).amount
                      ? parseFloat(
                          totalSelectedAmount.find(
                            (item: any) =>
                              item.currencyCode === selectedcurrency
                          )?.amount || "0"
                        ).toFixed(2)
                      : ""
                  }
                  onChange={(e) => {
                    // setAmount(e.target.value);
                    // dispatch(updateAmountMode(e.target.value));
                  }}
                  disabled={true}
                />
              </Col>
            </>
          )}
        </InputGroup>
      </Col>
      <Col lg={1}>
        {/*<Button variant="secondary" disabled={disableFields}>
          <FontAwesomeIcon icon={faSave} className="me-1" />
          <span>{t("resource_1:pay")}</span>
        </Button>
  */}
      </Col>
    </Form.Group>
  );
};

export default PayableAmount;
