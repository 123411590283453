import { createSlice } from '@reduxjs/toolkit';

import { fetchUserSuggestions } from '../blRequestInstruction/components/actions/Instruction';
import { getDurationOptions, getNationalIdOptions, getPortIdDocList, getPortIdDocumentNames, getPortIDRequest, getPortIdTypeOptions } from './portIdActions';

interface IPortIdSlice {
    documentData: Array<any>;
    nationalIdOptions: Array<any>;
    userSuggestions:Array<any>;
    durationOptions: Array<any>;
    portIdTypeOptions:Array<any>;
}

const initialState: IPortIdSlice = {
    documentData: [],
    nationalIdOptions: [],
    userSuggestions:[],
    durationOptions: [],
    portIdTypeOptions:[]
};

export const portIDSlice = createSlice({
    name: 'portId',
    initialState,
    reducers: {
        updateDocumentTableRow: (state, action) => {
            const row: any = state.documentData.find((x) => action.payload.rowNumber == x.rowNumber)
            if (!row) return
            Object.entries(action.payload).forEach(([key, val]) => { row[key] = val })
        },
        removeDocumentTableRow: (state, action) => {
            state.documentData = state.documentData.filter((x) => x.rowNumber != action.payload)
        },
        addDocumentTableRow: (state, action) => {
            state.documentData.push({
                rowNumber: action.payload,
                attId: null,
                docId: 12,
                docName: 'Others',
                fileName: '',
                filePath: '',
                tempName: '',
                serverFilePath: '',
                m: true,
                loading: 0
            })
        },
        updateDocumentList: (state, action) => {
            state.documentData=action.payload
        },
        clearDocumentList: (state)=>{
            state.documentData = state.documentData.filter((x)=>x.docId != 12).map((x)=>({
                ...x,
                serverFilePath:'',
                tempName: '',
                fileName: '',
                filePath: '',
                loading: 0,
            }))
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPortIdDocList.fulfilled, (state, action) => {
            state.documentData = action.payload
        })
        builder.addCase(getPortIdDocumentNames.fulfilled, (state, action)=>{
            state.documentData = state.documentData?.map((s)=>({
                ...s,
                docName: action.payload.find((x)=>x.docId == s.docId).docName,
                m: action.payload.find((x)=>x.docId == s.docId).m
            }))
        })
        
        builder.addCase(fetchUserSuggestions.fulfilled, (state,action)=>{
            state.userSuggestions = action.payload
        })

        builder.addCase(getNationalIdOptions.fulfilled, (state,action)=>{
            state.nationalIdOptions = action.payload
        })
        builder.addCase(getDurationOptions.fulfilled, (state,action)=>{
            state.durationOptions = action.payload
        })
        builder.addCase(getPortIdTypeOptions.fulfilled, (state,action)=>{
            state.portIdTypeOptions = action.payload
        })

        builder.addCase(getPortIDRequest.fulfilled, (state,action)=>{
                state.documentData = action.payload?.documents ?? []
        })
    }

});

export const {
    updateDocumentTableRow, addDocumentTableRow, removeDocumentTableRow, clearDocumentList,updateDocumentList
} = portIDSlice.actions;

export default portIDSlice;
